export const appHead = {"meta":[{"name":"viewport","content":"minimum-scale=1, initial-scale=1, width=device-width"},{"charset":"utf-8"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css"},{"rel":"icon","type":"image/x-icon","href":"https://shop.ducatimilano.com/ducati-favicon.ico"}],"style":[],"script":[{"src":"https://apis.google.com/js/platform.js?onload=renderBadge","async":true,"defer":true},{"innerHTML":"\n            window.renderBadge = function() {\n              var ratingBadgeContainer = document.createElement(\"div\");\n              document.body.appendChild(ratingBadgeContainer);\n              window.gapi.load('ratingbadge', function() {\n                window.gapi.ratingbadge.render(ratingBadgeContainer, {\n                  \"merchant_id\": 347508056\n                });\n              });\n            }\n          "}],"noscript":[],"charset":"utf-8","viewport":"minimum-scale=1, initial-scale=1, width=device-width","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"